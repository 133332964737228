.maindiv {
  height: 100vh;
  margin: 0;
}

.path-editor-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.innerdiv {
  height: 85vh;
  display: flex;
  align-items: flex-start;
}

.AColor {
  color: #87ace0;
  margin-left: 3px;
}

.BColor {
  color: #9187e0;
  margin-left: 3px;
}

.button {
  height: 8vh;
}

.buttons {
  margin: auto;
  width: 90vw;
  height: 7vh;
  justify-content: center;
}

.name-div {
  width: 20vw;
  font-size: 2.5vw;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 550px) {
  .name-div {
    display: none;
  }
}

a {
  margin: 3vh;
  height: 9vh;
  position: absolute;
  left: 0;
}

img {
  height: 9vh;
  max-height: 10vw;
}

h1 {
  color: #4674cf;
  letter-spacing: 0.6vw;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #3e4bde;
}

header {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

h3 {
  color: #6290c8;
  font-size: 2.5vw;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #3e4bde;
}

svg {
  outline: 2px #9d96b8 solid;
  max-width: 80vw;
  max-height: 80vw;
  min-width: 84vh;
  min-height: 84vh;
}

body {
  background-color: #6260b5;
  margin: 0;
}
